import React, { useEffect } from 'react';
import { getProducts } from './api/api';
import { IProduct } from './api/types';
import { useAuth } from 'react-oidc-context';

export interface ITenant {
  id: number;
  name: string;
}

export interface StaticData {
  products: Array<IProduct>;
}

type ContextState =
  | { status: 'LOADING' }
  | { status: 'ERROR'; errors?: string[] }
  | { status: 'LOADED'; staticData: StaticData };

const Context = React.createContext<StaticData>({ products: [] });

export const useStaticData = (): StaticData => {
  const contextState = React.useContext(Context);
  if (contextState === null) {
    throw new Error(
      'useStaticData must be used within a useStaticData tag',
    );
  }
  return contextState;
};

export const StaticDataProvider: any = (props: any) => {
  const auth = useAuth();
  const [state, setState] = React.useState<ContextState>({
    status: 'LOADING',
  });

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const [products] = await Promise.all([getProducts()]);
        if (products.status === 'Error') {
          console.error(products.errorMessages);
          setState({
            status: 'ERROR',
            errors: products.errorMessages,
          });
        }

        if (products.status === 'OK') {
          setState({
            status: 'LOADED',
            staticData: {
              products: products.data,
            },
          });
        }
      } catch (error) {
        console.error(error);
        setState({
          status: 'ERROR',
          errors: ['Something went wrong'],
        });
      }
    })();
  }, []);

  if (state.status === 'LOADED') {
    return (
      <Context.Provider value={state.staticData}>
        {props.children}
      </Context.Provider>
    );
  }

  if (state.status === 'LOADING') {
    return <div>Loading data</div>;
  }

  return (
    <div className="flex h-screen justify-center items-center">
      <div className="flex flex-col items-center bg-gray-50 max-w-sm rounded px-6 py-4 ">
        <div className="font-bold text-l p-2">
          Something went wrong 😭
        </div>
        <div className="text-s p-2">
          Make sure you have logged with an Easee employee account
          that has admin access.
        </div>
        <button
          onClick={() => auth.removeUser()}
          className={`bg-blue-500 text-white text-s p-3 rounded hover:bg-blue-700`}
          type="button"
        >
          Log out
        </button>
      </div>
    </div>
  );
};
