import { Result } from '../../api/api';

type StatusParams = {
  result: Result<string>;
};

export const StatusField = ({ result }: StatusParams) => {
  if (result.status === 'OK') {
    return <div className="font-thin text-xs">Success!</div>;
  }
  if (result.status === 'Loading') {
    return (
      <div className="flex flex-row items-center font-thin text-xs">
        <Loading />
        <span>Loading</span>
      </div>
    );
  }
  if (result.status === 'Error')
    return (
      <div>
        {result.errorMessages.map((e, i) => {
          return (
            <div className="font-thin text-xs" key={i}>
              {e}
            </div>
          );
        })}
      </div>
    );
  return <div>&nbsp;</div>;
};

const Loading = () => {
  return (
    <svg
      className="animate-spin h-5 w-5 m-3 ..."
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="black"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-100"
        fill="white"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
