import { useState } from 'react';
import {
  Result,
  setDesiredFirmwareAndTryUpgrade,
} from '../../api/api';
import { IFirmware } from '../../api/types';
import { Button } from '../Common/Button';
import { StatusField } from '../Common/StatusLabel';

interface Params {
  productCode: string;
  firmware: IFirmware;
  defaultDevicesToUpgrade?: number;
  variant: string;
}

export function PrepareNumberOfDevicesForFirmware({
  productCode,
  firmware,
  defaultDevicesToUpgrade = 0,
  variant,
}: Params) {
  const [devicesToUpgrade, setDevicesToUpgrade] = useState<string>(
    defaultDevicesToUpgrade.toString(),
  );
  const [firmwareAsDesiredResult, setFirmwareAsDesiredResult] =
    useState<Result<string>>({
      status: '',
    });

  const setDesiredFirmwareAndTryUpgradeFunc = async () => {
    setFirmwareAsDesiredResult({ status: 'Loading' });
    const result = await setDesiredFirmwareAndTryUpgrade(
      productCode,
      Number(devicesToUpgrade),
      firmware.firmwareId,
      variant,
    );
    setFirmwareAsDesiredResult(result);
  };
  return (
    <div className="flex flex-col content-center items-center">
      <div className="flex-row mt-2">
        <input
          className="bg-purple-white rounded border-2 border-grey p-3 text-sm mr-2"
          value={devicesToUpgrade}
          onChange={(v) => setDevicesToUpgrade(v.currentTarget.value)}
        />
        <Button
          onClick={() => setDesiredFirmwareAndTryUpgradeFunc()}
          text={'Send'}
        />
      </div>
      <StatusField result={firmwareAsDesiredResult} />
    </div>
  );
}
