import { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth, hasAuthParams } from 'react-oidc-context';

function Login() {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect({
        extraQueryParams: {
          kc_idp_hint: 'easee',
        },
      });
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
  ]);

  if (auth.activeNavigator) {
    return <div>Signing you in/out...</div>;
  }

  if (auth.error) {
    console.error(auth.error);
    return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    return <div>Unable to log in</div>;
  }

  return (
    <Navigate to="/products" replace state={{ from: location }} />
  );
}

export default Login;
