interface IParams {
  currentCount: number;
  targetCount: number;
}

export const StepProgress = ({
  currentCount,
  targetCount,
}: IParams) => {
  const percentage =
    currentCount > targetCount
      ? 100
      : (currentCount / targetCount) * 100;

  return (
    <>
      <div className="h-2 relative max-w-xl overflow-hidden">
        <div className="w-full h-full bg-blue-100 absolute"></div>
        <div
          className="mt-0.5 h-1 bg-blue-500 relative w-0"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>

      <div className="flex mt-1">
        <div className="flex-1">
          {currentCount} / {targetCount}
        </div>
        <div className="flex-1 text-right">
          {Math.round(percentage)}%
        </div>
      </div>
    </>
  );
};
