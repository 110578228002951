import ReactTooltip from 'react-tooltip';

interface IDisabled {
  disabled: boolean;
  reason: string;
}

interface IProps {
  onClick: () => void;
  text: string;
  disabled?: IDisabled[];
}

export const Button = ({ onClick, text, disabled }: IProps) => {
  const isDisabled = disabled && disabled.some((d) => d.disabled);
  const tooltipId = Math.random().toString();
  return (
    <>
      <button
        disabled={isDisabled}
        className={`bg-blue-500  text-white text-sm font-thin py-2 p-3 rounded h-10 ${
          isDisabled ? 'disabled:opacity-50' : 'hover:bg-blue-700'
        }`}
        onClick={onClick}
        data-tip
        data-for={tooltipId}
      >
        {text}
      </button>
      {isDisabled && (
        <ReactTooltip
          id={tooltipId}
          place="top"
          effect="solid"
          multiline={true}
        >
          <ul>
            {disabled.map((d, i) => {
              if (d.disabled) {
                return <li key={i}>{d.reason}</li>;
              }
            })}
          </ul>
        </ReactTooltip>
      )}
    </>
  );
};
