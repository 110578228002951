import { useNavigate } from 'react-router-dom';
import { useStaticData } from '../../StaticDataProvider';
import { getDeviceType } from '../../Types';
import { DeviceSearch } from '../DeviceSearch/DeviceSearch';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';

export const ProductOverview = () => {
  const navigate = useNavigate();
  const staticData = useStaticData();

  return (
    <>
      <div className="inset-x-0 w-full top-0 h-16">
        <DropdownMenu />
      </div>
      <div className="md:container md:mx-auto">
        <DeviceSearch />
        <section className="table w-full p-3.5">
          <table className="w-full border">
            <thead>
              <tr className="bg-gray-50 border-b">
                <th className="p-2 border-r text-sm font-thin text-gray-500">
                  Name
                </th>
                <th className="p-2 border-r text-sm font-thin text-gray-500">
                  Variant
                </th>
                <th className="p-2 border-r text-sm font-thin text-gray-500">
                  Code
                </th>
                <th className="p-2 border-r text-sm font-thin text-gray-500">
                  Device Type
                </th>
              </tr>
            </thead>
            <tbody>
              {staticData.products
                .sort((x, y) => x.code - y.code)
                .map((p) => {
                  return (
                    <tr
                      key={p.code + p.variant}
                      className="bg-gray-50 text-center hover:bg-gray-200 cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/Products/${p.code}/Variant/${p.variant}`,
                        )
                      }
                    >
                      <td className="p-2 border-r">{p.name}</td>
                      <td className="p-2 border-r">{p.variant}</td>
                      <td className="p-2 border-r">{p.code}</td>
                      <td className="p-2 border-r">
                        {getDeviceType(p.code.toString())}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </section>
      </div>
    </>
  );
};
