import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IProductStatistic } from '../../api/types';
import { Section } from '../Common/Section';

interface IParams {
  productStatistic: IProductStatistic[];
  productCode: string;
  variant: string;
}

export const DevicesPerFirmwareTable = ({
  productStatistic,
  productCode,
  variant,
}: IParams) => {
  const [showDevelopmentFirmwares, setShowDevelopmentFirmwares] =
    useState(false);

  const [sortByFirmware, setSortByFirmware] = useState(true);

  const statisticSorted = productStatistic.sort((a, b) =>
    sortByFirmware
      ? a.firmwareRelease < b.firmwareRelease
        ? 1
        : -1
      : a.deviceCount < b.deviceCount
      ? 1
      : -1,
  );

  const firmwares = showDevelopmentFirmwares
    ? statisticSorted
    : statisticSorted.filter(
        (s) => s.deviceCount > 0 && s.firmwareRelease <= 1000,
      );

  return (
    <Section>
      <div className="w-full">
        <div className="flex mt-6 mb-2">
          <label className="flex items-center">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={showDevelopmentFirmwares}
              onChange={() =>
                setShowDevelopmentFirmwares(!showDevelopmentFirmwares)
              }
            />
            <span className="ml-2">show development firmwares</span>
          </label>
        </div>
        <div>
          <table className="w-full border">
            <thead>
              <tr className="bg-gray-50 border-b">
                <th
                  onClick={() => {
                    setSortByFirmware(true);
                  }}
                  className="p-2 border-r cursor-pointer text-sm font-thin text-gray-500"
                >
                  Firmware{' '}
                  {sortByFirmware ? '▼' : '\u00a0\u00a0\u00a0'}
                </th>
                <th className="p-2 border-r cursor-pointer text-sm font-thin text-gray-500">
                  Semantic Version
                </th>
                <th
                  onClick={() => {
                    setSortByFirmware(false);
                  }}
                  className="p-2 border-r cursor-pointer text-sm font-thin text-gray-500"
                >
                  Devices{' '}
                  {sortByFirmware ? '\u00a0\u00a0\u00a0' : '▼'}
                </th>
              </tr>
            </thead>
            <tbody>
              {firmwares.map((s) => {
                return (
                  <tr
                    key={s.firmwareRelease}
                    className="bg-gray-50 text-center hover:bg-gray-200"
                  >
                    <td className="p-2 border-r">
                      <Link
                        to={`/Products/${productCode}/Variant/${variant}/Firmware/${s.firmwareRelease}`}
                      >
                        {s.firmwareRelease === -1
                          ? 'unknown'
                          : s.firmwareRelease}
                        {s.isCurrent ? ' (current)' : ''}
                      </Link>
                    </td>
                    <td className="p-2 border-r">
                      {s.semanticVersion}
                    </td>
                    <td className="p-2 border-r">{s.deviceCount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Section>
  );
};
