import { format } from 'date-fns';
import { deleteFirmware, OK, Result } from '../../api/api';
import { IFirmware, IFirmwareStatistic } from '../../api/types';
import { GridSection } from '../Common/Section';
import { UpdateProgress } from './UpdateProgress';
import { Button } from '../Common/Button';
import { canDeleteFirmware } from '../../api/rules';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

interface IParams {
  firmware: OK<IFirmware>;
  firmwareStatistic: OK<IFirmwareStatistic>;
  devicesOnProduct: number;
}

export const FirmwareInformation = ({
  firmware,
  firmwareStatistic,
  devicesOnProduct,
}: IParams) => {
  const navigate = useNavigate();
  const [deleteFeedback, setDeleteFeedback] = useState<string>('');
  return (
    <GridSection>
      <div className="col-span-6">
        <div className="text-3xl pb-2">Information</div>
        <div className="font-thin">
          <div>
            <span>Version:</span>
            <span> {firmware.data.firmwareId}</span>
          </div>
          <div>
            <span>Released: </span>
            <span>
              {format(
                new Date(firmware.data.releaseDate),
                'd-MM-yyyy',
              )}
            </span>
          </div>
          <div>
            <span>Is current firmware: </span>
            <span>{firmware.data.isCurrent ? 'true' : 'false'}</span>
          </div>
          <div>
            <span>Size: </span>
            <span>{formatBytes(firmware.data.size)}</span>
          </div>
        </div>
      </div>

      <div className="text-xl mt-3 col-span-6 flex flex-col items-end">
        <Button
          onClick={handleDelete}
          text={'Delete firmware'}
          disabled={[
            {
              disabled: !canDeleteFirmware(
                firmware.data,
                firmwareStatistic.data,
              ),
              reason: 'This firmware can not be deleted',
            },
          ]}
        />
        <text>{deleteFeedback}</text>
      </div>

      <UpdateProgress
        updatedCount={firmwareStatistic.data.reportedCount}
        desiredCount={firmwareStatistic.data.desiredCount}
        totalCount={devicesOnProduct}
      />
    </GridSection>
  );

  function handleDelete() {
    if (!canDeleteFirmware(firmware.data, firmwareStatistic.data)) {
      setDeleteFeedback('Action not allowed!');
      return;
    }

    deleteFirmware(
      firmware.data.productCode,
      firmware.data.variant,
      firmware.data.firmwareVersion,
    ).then((response: Result<string>) => {
      switch (response.status) {
        case 'OK':
          navigate(
            `/Products/${firmware.data.productCode}/Variant/${firmware.data.variant}`,
          );
          break;
        case 'Error':
          setDeleteFeedback(response.errorMessages.join(', '));
          break;
        default:
          setDeleteFeedback('Something went wrong...');
          break;
      }
    });
  }
};

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  );
}
