import { useNavigate, useParams } from 'react-router-dom';
import { useStaticData } from '../../StaticDataProvider';

export const DropdownMenu = () => {
  const staticData = useStaticData();
  const navigate = useNavigate();
  const { productCode, variant } = useParams();

  const getSelectedIndex = (
    productCode: string | undefined,
    variant: string | undefined,
  ) => {
    const isValid = isParams({
      productCode: productCode,
      variant: variant,
    });
    if (isValid)
      return staticData.products.findIndex(
        (p) =>
          productCode === p.code.toString() && variant === p.variant,
      );
    return -1;
  };

  const selectedIndex = getSelectedIndex(productCode, variant);

  const changeProduct = (selectedProductIndex: string) => {
    const index = selectedProductIndex
      ? Number.parseInt(selectedProductIndex)
      : -1;

    if (index < 0) return;

    const selectedProduct = staticData.products[index];
    navigate(
      `/Products/${selectedProduct.code}/Variant/${selectedProduct.variant}`,
    );
  };

  const productOptions = [
    <option value={-1} key={-1} disabled>
      -- Select product --
    </option>,
    ...staticData.products
      .sort(
        (p1, p2) =>
          p1.code - p2.code + (p1.variant.length - p2.variant.length),
      )
      .map((p, index) => {
        return (
          <option value={index} key={index}>
            {p.name} | {p.variant}
          </option>
        );
      }),
  ];

  return (
    <div
      className="h-14 pl-3.5 flex justify-start"
      style={{ backgroundColor: 'white' }}
    >
      <select
        value={selectedIndex}
        onChange={(e) => changeProduct(e.currentTarget.value)}
      >
        {productOptions}
      </select>
    </div>
  );
};

type ParamsResult = Params | NoParams;

type Params = {
  productCode?: string;
  variant?: string;
};

type NoParams = {
  error: string;
};

function isParams(result: ParamsResult): result is Params {
  return (
    (result as Params).productCode !== undefined &&
    (result as Params).variant !== undefined
  );
}
