import { useState } from 'react';
import {
  OK,
  Result,
  postUpgradeModems,
  postUpgradeDevices,
} from '../../api/api';
import { Button } from '../Common/Button';
import { BarLoader } from 'react-spinners';

interface ServiceBoxProps {
  header: string;
  description: string;
  children: JSX.Element | JSX.Element[];
}

interface ServiceBoxPropsList {
  children: JSX.Element | JSX.Element[];
}

const ServiceBox = ({
  header,
  description,
  children,
}: ServiceBoxProps) => {
  return (
    <div className="max-w-xlg rounded overflow-hidden shadow-lg px-8 py-8">
      <div className="font-bold text-xl mb-2">{header}</div>
      <p className="text-gray-700 text-base mt-2">{description}</p>
      {children}
    </div>
  );
};

const ServiceBoxList = ({ children }: ServiceBoxPropsList) => {
  return (
    <div className="flex justify-start mx-4 my-4">{children}</div>
  );
};

export const ServicesOverview = () => {
  const [devicesUpgradedResult, setDevicesUpgradedResult] = useState<
    Result<string>
  >({ status: '' });
  const [loadingDeviceUpgrade, setloadingDeviceUpgrade] =
    useState<boolean>(false);
  const [modemsUpgradedResult, setModemsUpgradedResult] = useState<
    Result<string>
  >({ status: '' });
  const [loadingModemUpgrade, setloadingModemUpgrade] =
    useState<boolean>(false);

  const setDevicesUpgraded = async () => {
    setloadingDeviceUpgrade(true);
    const result = await postUpgradeDevices();
    setloadingDeviceUpgrade(false);
    setDevicesUpgradedResult(result);
  };

  const setModemsUpgraded = async () => {
    setloadingModemUpgrade(true);
    const result = await postUpgradeModems();
    setloadingModemUpgrade(false);
    setModemsUpgradedResult(result);
  };

  const getDevicesUpgradedText = () => {
    if (devicesUpgradedResult.status === 'OK') {
      return devicesUpgradedResult.data;
    } else return '';
  };

  const getModemUpgradedText = () => {
    if (modemsUpgradedResult.status === 'OK') {
      return modemsUpgradedResult.data;
    } else return '';
  };

  return (
    <>
      <ServiceBoxList>
        <ServiceBox
          header="Upgrade"
          description="Perform batch upgrade for devices or modems"
        >
          <div className="grid grid-cols-2 gap-3 flex items-center my-5">
            <Button
              text="Upgrade devices"
              onClick={setDevicesUpgraded}
              disabled={[
                {
                  disabled: devicesUpgradedResult.status == 'OK',
                  reason: (devicesUpgradedResult as OK<string>).data,
                },
              ]}
            />
            {loadingDeviceUpgrade ? (
              <BarLoader color="#4B5058" />
            ) : (
              <p>{getDevicesUpgradedText()}</p>
            )}

            <Button
              text="Upgrade modems"
              onClick={setModemsUpgraded}
              disabled={[
                {
                  disabled: modemsUpgradedResult.status == 'OK',
                  reason: (modemsUpgradedResult as OK<string>).data,
                },
              ]}
            />
            {loadingModemUpgrade ? (
              <BarLoader color="#4B5058" />
            ) : (
              <p>{getModemUpgradedText()}</p>
            )}
          </div>
        </ServiceBox>
      </ServiceBoxList>
    </>
  );
};

export default ServicesOverview;
