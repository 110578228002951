import { GridSection } from '../Common/Section';
import { StepProgress } from './StepProgress';
import { StepStatus } from './StepStatus';

interface IStepStatus {
  ok: boolean;
  message: string;
}

interface IStepProgress {
  targetCount: number;
  currentCount: number;
}

interface IProps {
  disabled: boolean;
  children: JSX.Element | JSX.Element[];
  stepStatus: IStepStatus;
  title: string;
  subtitle: JSX.Element | string;
  number: number;
  stepProgress?: IStepProgress;
}

export const Step = ({
  disabled,
  stepStatus,
  children,
  number,
  title,
  subtitle,
  stepProgress,
}: IProps): JSX.Element => {
  return (
    <GridSection key={number} disabled={disabled}>
      <div className="col-span-1 text-5xl">{number}</div>
      <div className="col-span-2">
        <div>{title}</div>
        <div className="text-xs pt-2 font-light">{subtitle}</div>
        {stepProgress && (
          <div className="text-xs pt-4 font-light">
            <StepProgress
              currentCount={stepProgress.currentCount}
              targetCount={stepProgress.targetCount}
            />
          </div>
        )}
      </div>
      <div className="col-span-6 flex flex-row justify-center p-3.5">
        {children}
      </div>
      <StepStatus {...stepStatus} />
    </GridSection>
  );
};
