import { useState } from 'react';
import { Result, getDevice } from '../../api/api';
import { IDevice } from '../../api/types';
import { Button } from '../Common/Button';
import { DeviceBasicInformation } from '../DeviceBasicInformation/DeviceBasicInformation';

interface IProps {
  onSearch?: (device: Result<IDevice>) => void;
}

export const DeviceSearch = ({ onSearch }: IProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState<Result<IDevice>>({
    status: '',
  });

  const search = async () => {
    setSearchResult({ status: 'Loading' });
    const result = await getDevice(searchTerm);
    setSearchResult(result);
    if (onSearch) {
      onSearch(result);
    }
  };

  return (
    <section className="flex flex-col p-3.5">
      <div className="flex-row flex-grow justify-start">
        <input
          className="bg-purple-white rounded border-2 border-grey p-3 mr-4"
          placeholder="EH062687"
          value={searchTerm}
          onChange={(v) =>
            setSearchTerm(v.currentTarget.value?.toUpperCase())
          }
        />
        <Button onClick={search} text={'Search'} />
      </div>
      <DeviceBasicInformation deviceResult={searchResult} />
    </section>
  );
};
