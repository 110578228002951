import './App.css';

import {
  Route,
  BrowserRouter,
  Navigate,
  Routes,
  Outlet,
  useLocation,
} from 'react-router-dom';

import { useAuth, AuthProvider } from 'react-oidc-context';

import Login from './Components/Login/Login';
import { StaticDataProvider } from './StaticDataProvider';
import { Product } from './Components/Product/Product';
import { Device } from './Components/Device/Device';
import { Firmware } from './Components/Firmware/Firmware';
import { NavBar } from './Components/NavBar/NavBar';
import { ProductOverview } from './Components/ProductOverview/ProductOverview';
import { ServicesOverview } from './Components/ServicesOverview/ServicesOverview';
import { OidcUserManager } from './Components/Auth/auth';

function App() {
  return (
    <AuthProvider userManager={OidcUserManager}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<ProtectedLayout />}>
            <Route path="/products" element={<ProductOverview />} />
            <Route path="/services" element={<ServicesOverview />} />
            <Route
              path="/Products/:productCode/Variant/:variant/Firmware/:firmwareVersion"
              element={<Firmware />}
            />
            <Route
              path="/Products/:productCode/Devices/:deviceId"
              element={<Device />}
            />
            <Route
              path="/Products/:productCode/Variant/:variant"
              element={<Product />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export const ProtectedLayout = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  if (!isAuthenticated) {
    // user is not authenticated
    return (
      <Navigate to="/login" replace state={{ from: location }} />
    );
  }

  if (location.pathname === '/') {
    return <Navigate to="/products" />;
  }
  return (
    <StaticDataProvider>
      <NavBar />
      <Outlet />
    </StaticDataProvider>
  );
};

export default App;
