import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Result,
  getDevice,
  sendUpgradeToLatestCommand,
  getDeviceEvents,
} from '../../api/api';
import { IDevice, IDeviceEvent } from '../../api/types';
import { Button } from '../Common/Button';
import { Section } from '../Common/Section';
import { DeviceBasicInformation } from '../DeviceBasicInformation/DeviceBasicInformation';
import { SendSetFirmware } from './SendSpecificFirmware';

type DeviceParams = {
  productCode: string;
  deviceId: string;
};

export const Device = () => {
  const { deviceId } = useParams<DeviceParams>();

  const [deviceResult, setDeviceResult] = useState<Result<IDevice>>({
    status: '',
  });

  const [deviceEvents, setDeviceEvents] = useState<
    Result<IDeviceEvent[]>
  >({
    status: '',
  });

  useEffect(() => {
    (async (): Promise<void> => {
      setDeviceResult({ status: 'Loading' });
      const result = await getDevice(deviceId || '');
      setDeviceResult(result);
      setDeviceEvents({ status: 'Loading' });
      const deviceEventsResult = await getDeviceEvents(
        deviceId || '',
      );
      setDeviceEvents(deviceEventsResult);
    })();
  }, [deviceId]);

  return (
    <div className="p-3.5">
      <Section>
        <DeviceBasicInformation deviceResult={deviceResult} />
      </Section>
      <Section>
        <div>
          {deviceEvents.status === 'OK' &&
            deviceEvents.data.map((e) => {
              const time = format(
                new Date(e.timestamp),
                'dd-MM-yyyy HH:mm',
              );
              return (
                <div
                  key={e.timestamp}
                  className="flex flex-row text-sm font-mono"
                >
                  <div className="pr-5">{time}</div>
                  <div>{e.description}</div>
                </div>
              );
            })}
        </div>
      </Section>
      <Section>
        <div>
          {deviceResult.status === 'OK' && (
            <div>
              <SendUpgradeFirmware device={deviceResult.data} />
              <SendSetFirmware
                device={deviceResult.data}
                desiredFirmwareVersion={
                  deviceResult.data.desiredFirmwareVersion ||
                  deviceResult.data.firmwareVersion
                }
              />
            </div>
          )}
        </div>
      </Section>
    </div>
  );
};

interface IProps {
  device: IDevice;
}

const SendUpgradeFirmware = ({ device }: IProps): JSX.Element => {
  const [command, setCommand] = useState<Result<string>>({
    status: '',
  });

  const SendUpgradeFirmwareCommand = async () => {
    setCommand({ status: 'Loading' });
    const result = await sendUpgradeToLatestCommand(
      device.productCode,
      [device.serialNumber],
      device.variant,
    );
    setCommand(result);
  };

  return (
    <div className="bg-gray-50 w-50 rounded overflow-hidden mt-4">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">
          <div className="mb-5">Upgrade firmware </div>
          {command.status === 'Loading' && <div>Loading...</div>}
          <Button
            onClick={SendUpgradeFirmwareCommand}
            text={'Send command'}
          />
          <p className="mt-3 text-xs font-thin text-gray-600">
            This command will tell the device to upgrade <br /> to the
            current firmware in cloud.
          </p>
        </div>
      </div>
    </div>
  );
};
