import { useState } from 'react';
import { Result, setFirmwareAsDesired } from '../../api/api';
import { IDevice } from '../../api/types';
import { Button } from '../Common/Button';
import { StatusField } from '../Common/StatusLabel';

interface IProps {
  device: IDevice;
  desiredFirmwareVersion: number;
}

export const SendSetFirmware = ({
  device,
  desiredFirmwareVersion,
}: IProps): JSX.Element => {
  const [firmware, setFirmware] = useState<string>(
    desiredFirmwareVersion.toString(),
  );

  const [firmwareAsDesiredResult, setFirmwareAsDesiredResult] =
    useState<Result<string>>({ status: '' });

  const setFirmwareAsDesiredFunc = async () => {
    setFirmwareAsDesiredResult({ status: 'Loading' });
    const result = await setFirmwareAsDesired(
      device.productCode,
      [device.serialNumber],
      Number(firmware),
      device.variant,
    );
    setFirmwareAsDesiredResult(result);
  };
  return (
    <div className="bg-gray-50 w-50 rounded overflow-hidden m-4">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">
          <div className="mb-5">Set desired firmware</div>
          <div>
            <div className="flex-row mt-2">
              <input
                disabled={
                  firmwareAsDesiredResult.status === 'Loading'
                }
                className="bg-purple-white rounded border-2 border-grey p-3 text-sm"
                value={firmware}
                onChange={(v) => setFirmware(v.currentTarget.value)}
              />
              <Button
                onClick={setFirmwareAsDesiredFunc}
                text={'Send'}
              />
            </div>
            <StatusField result={firmwareAsDesiredResult} />
          </div>
          <p className="mt-3 text-xs font-thin text-gray-600">
            This command will tell the device to upgrade <br /> to the
            specific firmware.
          </p>
        </div>
      </div>
    </div>
  );
};
