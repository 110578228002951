import { getIdToken } from '../Components/Auth/auth';
import {
  IDevice,
  IDeviceEvent,
  IFirmware,
  IFirmwareStatistic,
  IProduct,
  IProductEvent,
  IProductStatistic,
} from './types';

export async function sendUpgradeToLatestCommand(
  productCode: number,
  serialnumbers: string[],
  variant: string,
): Promise<Result<string>> {
  const url = `/Products/${productCode}/Variants/${variant}/Devices/UpgradeToLatestRelease`;
  return await postJson<string>(url, {
    SerialNumbers: serialnumbers,
    UseBetaFirmware: false,
  });
}

export async function getFirmware(
  productCode: string,
  firmwareVersion: string,
  variant: string,
): Promise<Result<IFirmware>> {
  const url = `/Products/${productCode}/Variants/${variant}/Firmwares/${firmwareVersion}`;
  return await get<IFirmware>(url);
}

export async function setFirmwareAsCurrent(
  productCode: string,
  variant: string,
  firmwareVersion: string,
): Promise<Result<IFirmware>> {
  const url = `/Products/${productCode}/Variants/${variant}/Firmwares/Current`;
  return await put<IFirmware>(url, {
    firmwareVersion: firmwareVersion,
  });
}

export async function approveFirmwareByQualityAssurance(
  productCode: string,
  firmwareVersion: string,
  variant: string,
): Promise<Result<IFirmware>> {
  const url = `/Products/${productCode}/Variants/${variant}/Firmwares/${firmwareVersion}/ApproveByQualityAssurance`;
  return await put<IFirmware>(url, {});
}

export async function getDevicesWithFirmware(
  productCode: string,
  firmwareVersion: string,
  variant: string,
): Promise<Result<IDevice[]>> {
  const url = `/Products/${productCode}/Variants/${variant}/Firmwares/${firmwareVersion}/Devices`;
  return await get<IDevice[]>(url);
}

export async function setFirmwareAsDesired(
  productCode: number,
  serialnumbers: string[],
  firmware: number,
  variant: string,
): Promise<Result<string>> {
  const url = `/Products/${productCode}/Variants/${variant}/Devices/PrepareList`;
  return await put<string>(url, {
    SerialNumbers: serialnumbers,
    FirmwareRelease: firmware,
  });
}

export async function getDevice(
  searchTerm: string,
): Promise<Result<IDevice>> {
  const url = `/Devices/${searchTerm}`;
  return await get<IDevice>(url);
}

export async function getDeviceEvents(
  serialNumber: string,
): Promise<Result<IDeviceEvent[]>> {
  const url = `/Devices/${serialNumber}/Events`;
  return await get<IDeviceEvent[]>(url);
}

export async function getProducts(): Promise<Result<IProduct[]>> {
  const url = `/Products`;
  return await get<IProduct[]>(url);
}

export async function getProductStatistic(
  productCode: string,
  variant: string,
): Promise<Result<IProductStatistic[]>> {
  const url = `/Products/${productCode}/Variants/${variant}/Statistics`;
  return await get<IProductStatistic[]>(url);
}

export async function getFirmwareStatistic(
  productCode: string,
  firmwareVersion: string,
  variant: string,
): Promise<Result<IFirmwareStatistic>> {
  const url = `/Products/${productCode}/Variants/${variant}/Firmwares/${firmwareVersion}/stats`;
  return await get<IFirmwareStatistic>(url);
}

export async function getDevicesOnProduct(
  productCode: string,
  variant: string,
): Promise<Result<number>> {
  const url = `/Products/${productCode}/Variants/${variant}/Devices/Count`;
  return await get<number>(url);
}

export async function getProductEvent(
  productCode: string,
  variant: string,
): Promise<Result<IProductEvent[]>> {
  const url = `/Products/${productCode}/Variants/${variant}/Events`;
  return await get<IProductEvent[]>(url);
}

export async function postNewFirmware(
  productId: string,
  variantId: string,
  firmwareNumber: string,
  firmwareSemantic: string,
  firmware: File,
): Promise<Result<string>> {
  const formData = new FormData();
  formData.append('firmwareVersion', firmwareNumber);
  formData.append('file', firmware);
  formData.append('semanticVersion', firmwareSemantic);
  const url = `/Products/${productId}/Variants/${variantId}/Firmwares`;
  return await postForm<string>(url, formData);
}

export async function deleteFirmware(
  productId: number,
  variantId: string,
  firmwareVersion: string,
): Promise<Result<string>> {
  const url = `/Products/${productId}/Variants/${variantId}/Firmwares/${firmwareVersion}`;
  return await deleteHttp<string>(url);
}
export async function setDesiredFirmwareAndTryUpgrade(
  productCode: string,
  numberOfDevices: number,
  firmwareVersion: number,
  variant: string,
): Promise<Result<string>> {
  const url = `/Products/${productCode}/Variants/${variant}/Devices/PrepareNumberOfDevices`;
  return await put<string>(url, {
    numberOfDevices: numberOfDevices,
    firmwareVersion: firmwareVersion,
  });
}

export async function postFirmware(
  productCode: string,
  variant: string,
  formData: FormData,
): Promise<Result<string>> {
  const url = `/Products/${productCode}/Variants/${variant}/Firmwares`;
  return await postForm<string>(url, formData);
}

export async function postUpgradeDevices(): Promise<Result<string>> {
  const url = `/upgrades/devices`;
  return await postJson<string>(url, null);
}

export async function postUpgradeModems(): Promise<Result<string>> {
  const url = `/upgrades/modems`;
  return await postJson<string>(url, null);
}

export const isOk = <T>(data: Result<T>): data is OK<T> => {
  return data.status === 'OK';
};

export type OK<T> = {
  status: 'OK';
  data: T;
};

export type ERROR = {
  status: 'Error';
  errorMessages: string[];
};

export type LOADING = {
  status: 'Loading';
};

export type DEFAULT = {
  status: '';
};
export type Result<T> = OK<T> | ERROR | LOADING | DEFAULT;

const formDataOptions = async (method: 'POST' | 'PUT') => ({
  method: method,
  headers: {
    Accept: 'multipart/form-data',
    Authorization: `Bearer ${await getIdToken()}`,
    From: 'firmware-manager-app',
  },
});

const jsonOptions = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
) => ({
  method: method,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${await getIdToken()}`,
    'Content-Type': 'application/json',
    From: 'firmware-manager-app',
  },
});

async function get<T>(url: string): Promise<Result<T>> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}${url}`,
    await jsonOptions('GET'),
  );
  if (response.status === 200) {
    const result = await response.json();
    return {
      status: 'OK',
      data: result,
    };
  }
  if (response.status === 204) {
    return {
      status: 'Error',
      errorMessages: await tryParseErrorMessage(response),
    };
  }
  return {
    status: 'Error',
    errorMessages: await tryParseErrorMessage(response),
  };
}

async function deleteHttp<T>(url: string): Promise<Result<T>> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}${url}`,
    await jsonOptions('DELETE'),
  );
  if (response.status === 200) {
    const result = await response.json();
    return {
      status: 'OK',
      data: result,
    };
  }
  if (response.status === 204) {
    return {
      status: 'Error',
      errorMessages: await tryParseErrorMessage(response),
    };
  }
  return {
    status: 'Error',
    errorMessages: await tryParseErrorMessage(response),
  };
}

async function postForm<T>(
  url: string,
  body: FormData,
): Promise<Result<T>> {
  const postOptions = {
    ...(await formDataOptions('POST')),
    body: body,
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}${url}`,
    postOptions,
  );
  if (response.ok) {
    const result = await response.json();
    return {
      status: 'OK',
      data: result,
    };
  }
  return Promise.reject({
    status: 'Error',
    errorMessages: await tryParseErrorMessage(response),
  });
}

async function postJson<T>(
  url: string,
  body: any,
): Promise<Result<T>> {
  const postOptions = {
    ...(await jsonOptions('POST')),
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}${url}`,
    postOptions,
  );
  if (response.ok) {
    const result = await response.json();
    return {
      status: 'OK',
      data: result,
    };
  }
  return {
    status: 'Error',
    errorMessages: await tryParseErrorMessage(response),
  };
}

async function put<T>(url: string, body: any): Promise<Result<T>> {
  const putOptions = {
    ...(await jsonOptions('PUT')),
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}${url}`,
    putOptions,
  );
  if (response.ok) {
    const result = await response.json();
    return {
      status: 'OK',
      data: result,
    };
  }
  return {
    status: 'Error',
    errorMessages: await tryParseErrorMessage(response),
  };
}

const tryParseErrorMessage = async (
  response: Response,
): Promise<string[]> => {
  if (response) {
    try {
      const errorMessages = (await response.json()) as string[];
      return errorMessages;
    } catch (e) {
      return ['Something went wrong'];
    }
  }
  return ['Something went wrong'];
};
