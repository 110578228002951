import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getProductEvent,
  getProductStatistic,
  Result,
} from '../../api/api';
import { IProductEvent, IProductStatistic } from '../../api/types';
import { Section } from '../Common/Section';
import { DevicesPerFirmwareGraph } from './DevicesPerFirmwareGraph';
import { DevicesPerFirmwareTable } from './DevicesPerFirmwareTable';
import { VariantDropdown } from './VariantDropdown';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';

export type ProductParams = {
  productCode: string;
  variant: string;
};

export const Product = () => {
  const { productCode = '', variant = '' } =
    useParams<ProductParams>();
  const [statistic, setStatistic] = useState<
    Result<IProductStatistic[]>
  >({ status: 'Loading' });

  const [events, setEvents] = useState<Result<IProductEvent[]>>({
    status: 'Loading',
  });

  useEffect(() => {
    (async (): Promise<void> => {
      const result = await getProductStatistic(productCode, variant);
      setStatistic(result);
      const productEventresult = await getProductEvent(
        productCode,
        variant,
      );
      setEvents(productEventresult);
    })();
  }, [productCode, variant]);

  if (statistic.status === 'OK') {
    return (
      <>
        <div className="inset-x-0 w-full top-0 h-16">
          <DropdownMenu />
        </div>

        <div className="mt-10 md:container md:mx-auto">
          <VariantDropdown />
          <DevicesPerFirmwareGraph
            productStatistic={statistic.data}
            productCode={productCode}
            variant={variant}
          />
          <DevicesPerFirmwareTable
            productStatistic={statistic.data}
            productCode={productCode}
            variant={variant}
          />
          <Section>
            <div>
              {events.status === 'OK' &&
                events.data.map((e) => {
                  const time = format(
                    new Date(e.timestamp),
                    'dd-MM-yyyy HH:mm',
                  );
                  return (
                    <div
                      key={e.timestamp}
                      className="flex flex-row text-sm font-mono"
                    >
                      <div className="pr-5">{time}</div>
                      <div>{e.description}</div>
                    </div>
                  );
                })}
            </div>
          </Section>
        </div>
      </>
    );
  }

  if (statistic.status === 'Loading') {
    return <div>Loading</div>;
  }
  return <div>Error</div>;
};
