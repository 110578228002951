import { useNavigate, useParams } from 'react-router-dom';
import { useStaticData } from '../../StaticDataProvider';

export const VariantDropdown = () => {
  const navigate = useNavigate();
  const staticData = useStaticData();
  const { productCode, variant } = useParams();

  const variantsForProductCode = staticData.products.filter(
    (x) => x.code.toString() === productCode,
  );

  const selectedIndex =
    variant != undefined
      ? variantsForProductCode.findIndex((p) => variant === p.variant)
      : -1;

  const changeProduct = (selectedProductIndex: string) => {
    const index = selectedProductIndex
      ? Number.parseInt(selectedProductIndex)
      : -1;

    if (index < 0) return;

    navigate(
      `/Products/${productCode}/Variant/${variantsForProductCode[index].variant}`,
    );
  };

  const productOptions = [
    <option value={-1} key={-1} disabled>
      -- Select variant --
    </option>,
    ...variantsForProductCode.map((p, index) => {
      return (
        <option value={index} key={index}>
          {p.variant}
        </option>
      );
    }),
  ];

  return (
    <div className="grid column-1 justify-start h-14 m-2">
      <select
        value={selectedIndex}
        onChange={(e) => changeProduct(e.currentTarget.value)}
      >
        {productOptions}
      </select>
    </div>
  );
};
