interface ISectionParams {
  children: JSX.Element | JSX.Element[];
  disabled?: boolean;
}

export const Section = ({
  children,
  disabled = false,
}: ISectionParams) => {
  return (
    <div
      className={`bg-white p-10 m-2 flex flex-row justify-evenly ${
        disabled ? 'opacity-25' : ''
      }`}
    >
      {children}
    </div>
  );
};

export const GridSection = ({
  children,
  disabled = false,
}: ISectionParams) => {
  return (
    <div
      className={`bg-white p-10 m-2 grid grid-cols-12 gap-4 ${
        disabled ? 'opacity-25' : ''
      }`}
    >
      {children}
    </div>
  );
};
