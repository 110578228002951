import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  approveFirmwareByQualityAssurance,
  getDevicesOnProduct,
  getFirmware,
  getFirmwareStatistic,
  Result,
  setFirmwareAsCurrent,
} from '../../api/api';
import { IFirmware, IFirmwareStatistic } from '../../api/types';
import { Section } from '../Common/Section';
import { FallbackSection } from '../Common/Fallback';
import { Step } from './Step';
import { PrepareListOfDevicesForFirmware } from './PrepareListOfDevicesForFirmware';
import { Button } from '../Common/Button';
import { FirmwareInformation } from './FirmwareInformation';
import { PrepareNumberOfDevicesForFirmware } from './PrepareNumberOfDevicesForFirmware';
import { canBeRolledOut } from '../../api/rules';

export interface IStep {
  component: JSX.Element;
  success: boolean;
}

type Params = {
  productCode: string;
  firmwareVersion: string;
  variant: string;
};

export const Firmware = () => {
  const {
    productCode = '',
    firmwareVersion = '',
    variant = '',
  } = useParams<Params>();
  const [firmware, setFirmware] = useState<Result<IFirmware>>({
    status: '',
  });

  const [firmwareStatistic, setFirmwareStatistic] = useState<
    Result<IFirmwareStatistic>
  >({ status: '' });

  const [devicesOnProduct, setDevicesOnProduct] = useState<
    Result<number>
  >({ status: '' });

  useEffect(() => {
    (async (): Promise<void> => {
      const firmwareResult = await getFirmware(
        productCode,
        firmwareVersion,
        variant,
      );
      setFirmware(firmwareResult);
      const statisticResult = await getFirmwareStatistic(
        productCode,
        firmwareVersion,
        variant,
      );
      setFirmwareStatistic(statisticResult);
      const devicesOnProductResult = await getDevicesOnProduct(
        productCode,
        variant,
      );
      setDevicesOnProduct(devicesOnProductResult);
    })();
  }, [productCode, variant, firmwareVersion]);

  const setApprovedFirmwareByQualityAssurance = async () => {
    if (
      firmware.status !== 'OK' ||
      firmwareStatistic.status !== 'OK' ||
      !canBeRolledOut(firmware.data, firmwareStatistic.data)
    ) {
      console.log('Can not approve this firmware.');
      return;
    }
    const result = await approveFirmwareByQualityAssurance(
      productCode,
      firmwareVersion,
      variant,
    );
    setFirmware(result);
  };
  const setAsCurrent = async () => {
    const result = await setFirmwareAsCurrent(
      productCode,
      variant,
      firmwareVersion,
    );
    setFirmware(result);
  };

  if (firmware.status !== 'OK') {
    if (firmware.status === 'Error') {
      return (
        <Section>
          <div>
            <div className="text-xl pb-3">Information</div>
            <div className="font-light">
              <div>
                <span>Version:</span>
                <span> {firmwareVersion}</span>
              </div>
            </div>
          </div>
          <div>
            <div className="text-xl pb-3">Status</div>
            <div className="font-light">
              <div>
                {firmware.errorMessages.map((x, i) => {
                  return <div key={i}>{x}</div>;
                })}
              </div>
            </div>
          </div>
        </Section>
      );
    }
    return (
      <Section>
        <div>
          <div className="text-xl pb-3">Information</div>
          <div className="font-light">
            <div>
              <span>Version:</span>
              <span> {firmwareVersion}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="text-xl pb-3">Status</div>
          <div className="font-light">
            <div>Unknown firmware Id</div>
          </div>
        </div>
      </Section>
    );
  }

  if (firmwareStatistic.status !== 'OK') {
    return <FallbackSection result={firmwareStatistic} />;
  }

  if (devicesOnProduct.status !== 'OK') {
    return <FallbackSection result={devicesOnProduct} />;
  }

  const secondStepSuccess =
    firmwareStatistic.data.firmwareRelease > 1;
  const numberOfdevicesWithThisFirmware =
    firmwareStatistic.data.reportedCount;

  return (
    <div className="mt-10">
      <FirmwareInformation
        firmware={firmware}
        firmwareStatistic={firmwareStatistic}
        devicesOnProduct={devicesOnProduct.data}
      />
      <Step
        title="Send firmware to multiple devices"
        subtitle="Sends setSpecificFirmware command to all devices in list"
        number={1}
        disabled={false}
        stepStatus={{
          ok: secondStepSuccess,
          message: `Atleast ${1} device needs to run firmware ${firmwareVersion}. ${numberOfdevicesWithThisFirmware} are running it`,
        }}
      >
        <PrepareListOfDevicesForFirmware />
      </Step>
      <div
        className="p-6"
        hidden={canBeRolledOut(firmware.data, firmwareStatistic.data)}
      >
        This firmware can not be rolled out further.
      </div>
      <div
        hidden={
          !canBeRolledOut(firmware.data, firmwareStatistic.data)
        }
      >
        <Step
          title="Accepted by QA"
          subtitle="QA needs to verify that the firmware works"
          number={2}
          disabled={false}
          stepStatus={{
            ok: firmware.data.approvedByQualityAssurance,
            message: `Firmware ${firmwareVersion} needs to be accepted by QA`,
          }}
        >
          <Button
            disabled={[
              {
                disabled: !secondStepSuccess,
                reason: 'At least one device has to run the firmware',
              },
              {
                disabled: firmware.data.approvedByQualityAssurance,
                reason: 'Firmware already approved',
              },
            ]}
            onClick={setApprovedFirmwareByQualityAssurance}
            text={
              firmware.data.approvedByQualityAssurance
                ? `Accepted`
                : `Accept ${firmwareVersion}`
            }
          />
        </Step>
        <Step
          disabled={false}
          stepStatus={{
            ok: true,
            message: 'OK',
          }}
          title={'Update N devices'}
          subtitle={
            <UpdateNDevicesSubtitle
              isApprovedByQA={
                firmware.data.approvedByQualityAssurance
              }
              isCurrent={firmware.data.isCurrent}
            />
          }
          number={3}
        >
          <PrepareNumberOfDevicesForFirmware
            productCode={productCode}
            firmware={firmware.data}
            variant={variant}
          />
        </Step>
        <Step
          title="Set firmware as current"
          subtitle="All devices will now upgrade to this when get upgrade to latest command"
          number={4}
          disabled={!firmware.data.approvedByQualityAssurance}
          stepStatus={{
            ok: firmware.data.isCurrent,
            message: `Firmware ${firmwareVersion} needs to be set as current.`,
          }}
        >
          <Button
            disabled={[
              {
                disabled: !firmware.data.approvedByQualityAssurance,
                reason:
                  'Firmware needs to be approved by quality assurance',
              },
              {
                disabled: firmware.data.isCurrent,
                reason: 'Firmware is already set as current',
              },
            ]}
            onClick={setAsCurrent}
            text={`Set ${firmwareVersion} as current`}
          />
        </Step>
        <Step
          disabled={
            !firmware.data.approvedByQualityAssurance ||
            !firmware.data.isCurrent
          }
          stepStatus={{
            ok: true,
            message: 'OK',
          }}
          title={'Set firmware as desired for all devices'}
          subtitle={
            <UpdateNDevicesSubtitle
              isApprovedByQA={
                firmware.data.approvedByQualityAssurance
              }
              isCurrent={firmware.data.isCurrent}
            />
          }
          number={5}
        >
          <PrepareNumberOfDevicesForFirmware
            productCode={productCode}
            firmware={firmware.data}
            defaultDevicesToUpgrade={devicesOnProduct.data}
            variant={variant}
          />
        </Step>
      </div>
    </div>
  );
};

interface ISubtitleParams {
  isApprovedByQA: boolean;
  isCurrent: boolean;
}

const UpdateNDevicesSubtitle = ({
  isApprovedByQA,
  isCurrent,
}: ISubtitleParams) => {
  return (
    <ul>
      <li>Max number of devices that can be updated</li>
      <li>
        ✓ default: <b>1000</b>
      </li>
      <li>
        {isApprovedByQA ? '✓' : '✕'} Approved by QA: <b>20 000</b>
      </li>
      <li>
        {isCurrent ? '✓' : '✕'} Set as current: <b>Unlimited</b>
      </li>
    </ul>
  );
};
