interface IParams {
  updatedCount: number;
  desiredCount: number;
  totalCount: number;
}

export const UpdateProgress = ({
  updatedCount,
  desiredCount,
  totalCount,
}: IParams) => {
  return (
    <>
      <div className="col-span-12">
        <div className="h-6 relative w-full overflow-hidden align-middle text-right">
          <div className="w-full h-full bg-blue-200 absolute pr-1 pt-0.5 ">
            {totalCount}
          </div>
          <div
            className="mt-0.5 h-5 bg-blue-600 absolute pr-1 text-white"
            style={{
              width: `${(100 * desiredCount) / totalCount}%`,
            }}
          >
            {desiredCount}
          </div>
          <div
            className="mt-0.5 h-5 bg-blue-900 absolute pr-1 text-white"
            style={{
              width: `${(100 * updatedCount) / totalCount}%`,
            }}
          >
            {updatedCount}
          </div>
        </div>
      </div>

      <div className="col-span-4">
        <BulletPoint text="Updated" className="bg-blue-900" />
      </div>
      <div className="col-span-4">
        <BulletPoint text="Desired" className="bg-blue-600" />
      </div>
      <div className="col-span-4">
        <BulletPoint text="Total" className="bg-blue-200" />
      </div>
    </>
  );
};

const BulletPoint = ({
  text,
  className,
}: {
  text: string;
  className: string;
}) => (
  <>
    <div
      className={`rounded-full h-3 w-3 items-center justify-center inline-block mr-2 ${className}`}
    ></div>
    {text}
  </>
);
