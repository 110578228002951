interface IListElement {
  name: string;
  color: string;
}

interface IParams {
  elements: Array<IListElement>;
}

export const BulletList = ({ elements }: IParams) => {
  return (
    <div className="flex flex-col">
      {elements.map((element) => {
        return (
          <div key={element.name} className="flex flex-row pt-3.5">
            <div className="pr-2 pt-1">
              <div
                className="rounded-full h-3 w-3 flex items-center justify-center"
                style={{
                  backgroundColor: element.color,
                  color: element.color,
                }}
              >
                .
              </div>
            </div>
            <div className="font-light">{element.name}</div>
          </div>
        );
      })}
    </div>
  );
};
