import React, { useEffect, useState } from 'react';
import { postNewFirmware } from '../../api/api';
import { Button } from '../Common/Button';
import { Text } from 'recharts';
import { ProductParams } from '../Product/Product';
import { getDeviceType } from '../../Types';
import { getFirmwareFamily } from '../../api/rules';

enum UploadState {
  NotReady,
  Ready,
  Uploading,
  Success,
  Fail,
}

function FormFileUploader(params: ProductParams) {
  const { productCode, variant } = params;
  const [file, setFile] = useState<File>();
  const [firmwareNumber, setFirmwareNumber] = useState<string>();
  const [firmwareSemantic, setFirmwareSemantic] = useState<string>();
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [useFirmwareFamily, setUseFirmwareFamily] =
    useState<boolean>(true);
  const [uploadState, setUploadState] = useState<UploadState>(
    UploadState.NotReady,
  );

  function handleFileChange(selectorFiles: FileList | null) {
    if (selectorFiles && selectorFiles?.length > 0) {
      setFile(selectorFiles[0]);
    }
  }

  function validateSemanticVersion(semanticVersion: string): boolean {
    return /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(-[a-zA-Z]+\d*)?$/.test(
      semanticVersion,
    );
  }

  function validateFirmwareNumber(firmwareNumber: string): boolean {
    return /^[1-9]\d*$/.test(firmwareNumber);
  }

  function inputValidationFeedback() {
    if (!file || !firmwareNumber || !firmwareSemantic)
      return 'Please fill the form';
    if (!validateFirmwareNumber(firmwareNumber))
      return 'Invalid firmware version number';
    if (firmwareSemantic.startsWith('v'))
      return 'erroronous user, replace user\n or consider removing that v in front of the semantic version';
    if (!validateSemanticVersion(firmwareSemantic))
      return 'Invalid semantic version';
    return 'OK';
  }

  useEffect(() => {
    setUploadState(
      inputValidationFeedback() === 'OK'
        ? UploadState.Ready
        : UploadState.NotReady,
    );
  }, [firmwareNumber, file, firmwareSemantic]);

  function handleSubmit() {
    console.log(file);
    if (!file || !firmwareNumber || !firmwareSemantic) {
      throw new Error('Missing data');
    }
    console.log('Uploading...');
    setResponseMessage('Uploading...');
    setUploadState(UploadState.Uploading);

    const productsReceivingUpload = useFirmwareFamily
      ? getFirmwareFamily({ productCode, variant })
      : [{ productCode, variant }];

    Promise.all(
      productsReceivingUpload.map((it) =>
        postNewFirmware(
          it.productCode,
          it.variant,
          firmwareNumber,
          firmwareSemantic,
          file,
        ),
      ),
    ).then(
      (results) => {
        console.log(`Uploaded:`, results);
        setUploadState(UploadState.Success);
        setResponseMessage('Upload Success!');
      },
      (rejects) => {
        console.log(`Failed uploaded:`, rejects);
        setUploadState(UploadState.Fail);
        setResponseMessage(rejects.errorMessages.join(', '));
      },
    );
  }

  function FirmwareFamilyCheckbox() {
    const firmwareFamily = getFirmwareFamily({
      productCode,
      variant,
    });
    const othersInFamily = firmwareFamily
      .filter(
        (it) =>
          it.productCode !== productCode || it.variant !== variant,
      )
      .map((it) => `p${it.productCode}_v${it.variant}`)
      .join(', ');

    return firmwareFamily.length > 1 ? (
      <div className="flex-row space-x-3">
        <input
          defaultChecked={useFirmwareFamily}
          type="checkbox"
          onChange={() => setUseFirmwareFamily(!useFirmwareFamily)}
        />
        <label>{`Upload to others in firmware family: ${othersInFamily}`}</label>
      </div>
    ) : (
      <div />
    );
  }

  return (
    <div className="file-uploader flex flex-col space-y-3 mt-2">
      <h1 className="w-full mb-2 font-bold text-xl">
        Upload Firmware
      </h1>
      <div className="flex flex-row space-x-7">
        <label>
          Device
          <br />
          <b>{getDeviceType(productCode)}</b>
        </label>
        <label>
          Product code
          <br />
          <b className="flex flex-row-reverse">{productCode}</b>
        </label>
        <label>
          Variant
          <br />
          <b className="flex flex-row-reverse">{variant}</b>
        </label>
      </div>
      <label>
        Enter Firmware Version Number:
        <br />
        <input
          className="form-control block w-full mt-1 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          type="text"
          value={firmwareNumber}
          onChange={(e) => setFirmwareNumber(e.target.value)}
        />
      </label>
      <label>
        Enter Semantic Firmware Version:
        <br />
        <input
          className="form-control block w-full mt-1 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          type="text"
          value={firmwareSemantic}
          onChange={(e) => setFirmwareSemantic(e.target.value)}
        />
      </label>
      <input
        type="file"
        onChange={(e) => handleFileChange(e.target.files)}
        accept=".dat"
      />
      <p
        className="text-sm text-gray-500 dark:text-gray-300"
        id="file_input_help"
      >
        Supported: dat
      </p>
      <FirmwareFamilyCheckbox />
      <Button
        onClick={handleSubmit}
        text="Upload"
        disabled={[
          {
            disabled:
              uploadState == UploadState.NotReady ||
              uploadState == UploadState.Success ||
              uploadState == UploadState.Fail,
            reason: inputValidationFeedback(),
          },
          {
            disabled: uploadState == UploadState.Uploading,
            reason: 'Working...',
          },
        ]}
      />
      <Text>{responseMessage}</Text>
    </div>
  );
}

export default FormFileUploader;
