import { Link } from 'react-router-dom';
import { ReactComponent as EaseeLogo } from '../../assets/icons/easee-logo-white.svg';
import { useAuth } from 'react-oidc-context';

export const NavBar = () => {
  const auth = useAuth();
  return (
    <div
      className="h-14 pl-3.5 flex justify-start"
      style={{ backgroundColor: '#4B5058', color: 'white' }}
    >
      <div className="pt-5">
        <Link to="/products">
          <EaseeLogo width="75px" />
        </Link>
      </div>
      <div className="pl-10 pt-4">
        <Link to="/services">Services</Link>
      </div>
      <div className="pr-10 pt-4 ml-auto">
        <a href="#" onClick={() => auth.removeUser()}>
          Log Out
        </a>
      </div>
    </div>
  );
};
