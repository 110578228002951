export type User = AuthenticatedUser | NotAuthenticatedUser;

export type NotAuthenticatedUser = {
  isAuthenticated: false;
};
export interface AuthenticatedUser {
  isAuthenticated: true;
  accessToken: string;
  tokenType: string;
  expiresIn: number;
}

const chargerProductCodes = ['1', '2', '3', '100', '101', '400'];
const equalizerProductCodes = ['200', '201', '300', '301'];
const ampProductCodes = ['500'];
const powerBoardSimulatorProductCodes = ['42'];

export const getDeviceType = (productCode: string) => {
  if (chargerProductCodes.includes(productCode)) {
    return 'Charger';
  }
  if (equalizerProductCodes.includes(productCode)) {
    return 'Equalizer';
  }
  if (ampProductCodes.includes(productCode)) {
    return 'Equalizer AMP';
  }
  if (powerBoardSimulatorProductCodes.includes(productCode)) {
    return 'Power Board Simulator';
  }
  return 'Unknown';
};
