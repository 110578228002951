import { IFirmware, IFirmwareStatistic } from './types';

export function getFirmwareFamily(productType: {
  productCode: string;
  variant: string;
}): Array<{ productCode: string; variant: string }> {
  const { productCode: p, variant: v } = productType;

  switch (p) {
    case '1':
      return [productType, { productCode: '100', variant: v }];
    case '100':
      return [productType, { productCode: '1', variant: v }];
    case '200':
      return [productType, { productCode: '201', variant: v }];
    case '201':
      return [productType, { productCode: '200', variant: v }];
    default:
      return [productType];
  }
}

export function canDeleteFirmware(
  firmware: IFirmware,
  firmwareStatistics: IFirmwareStatistic,
) {
  return (
    firmware.firmwareId >= 1000 &&
    firmwareStatistics.reportedCount == 0
  );
}

export function canBeRolledOut(
  firmware: IFirmware,
  firmwareStatistics: IFirmwareStatistic, // eslint-disable-line @typescript-eslint/no-unused-vars
): boolean {
  return firmware.firmwareId < 1000;
}
