import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Result, setFirmwareAsDesired } from '../../api/api';
import { Button } from '../Common/Button';
import { StatusField } from '../Common/StatusLabel';

type Params = {
  productCode: string;
  firmwareVersion: string;
  variant: string;
};

export const PrepareListOfDevicesForFirmware = () => {
  const {
    productCode,
    firmwareVersion,
    variant = '',
  } = useParams<Params>();

  const [serialNumbers, setSerialNumbers] = useState<string>('');
  const [firmwareAsDesiredResult, setFirmwareAsDesiredResult] =
    useState<Result<string>>({
      status: '',
    });

  const setAsDesiredFunc = async () => {
    setFirmwareAsDesiredResult({ status: 'Loading' });
    const result = await setFirmwareAsDesired(
      Number(productCode),
      serialNumbers
        .trim()
        .replace(/(;| |, |\r?\n)/g, ',')
        .split(','),
      Number(firmwareVersion),
      variant,
    );
    setFirmwareAsDesiredResult(result);
  };
  return (
    <div>
      <div className="flex align-middle content-center items-center">
        <div>
          <textarea
            className="bg-purple-white rounded border-2 border-grey p-3"
            value={serialNumbers}
            onChange={(v) =>
              setSerialNumbers(v.target.value?.toUpperCase())
            }
            placeholder="EH062687, EH02321"
          />
        </div>
        <div className="pl-4">
          <Button onClick={setAsDesiredFunc} text={'Send'} />
        </div>
      </div>
      <StatusField result={firmwareAsDesiredResult} />
    </div>
  );
};
