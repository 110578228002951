import { Link } from 'react-router-dom';
import { Result } from '../../api/api';
import { IDevice } from '../../api/types';
import { StatusField } from '../Common/StatusLabel';
import { getDeviceType } from '../../Types';

interface IParams {
  deviceResult: Result<IDevice>;
}

export const DeviceBasicInformation = ({
  deviceResult: result,
}: IParams) => {
  if (result.status === 'OK') {
    const productCode = result.data.productCode.toString();
    return (
      <div className="bg-gray-50 max-w-sm rounded overflow-hidden mt-4">
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">
            <Link
              to={`/Products/${result.data.productCode}/Devices/${result.data.serialNumber}`}
            >
              {result.data.serialNumber}
            </Link>
          </div>
          <div className="text-s mb-2">
            device type: {getDeviceType(productCode)}
          </div>
          <div className="text-s mb-2">
            product code: {result.data.productCode}
          </div>
          <div className="text-s mb-2">
            variant: {result.data.variant}
          </div>
          <div className="text-s mb-2">
            current firmware: {result.data.firmwareVersion}
          </div>
          <div className="text-s mb-2">
            next firmware: {result.data.desiredFirmwareVersion}
          </div>
        </div>
      </div>
    );
  }
  return <StatusField result={result} />;
};
