interface IParams {
  ok: boolean;
  message: string;
}

export const StepStatus = ({ ok, message }: IParams) => {
  return (
    <div className="col-span-2">
      <div className="flex flex-row">
        <div className="pr-3">{ok ? '✅ ' : '⛔'}</div>
        <div className="font-light">{message}</div>
      </div>
    </div>
  );
};
