import { DEFAULT, ERROR, LOADING } from '../../api/api';
import { Section } from './Section';

interface IParams {
  result: ERROR | LOADING | DEFAULT;
}

export const Fallback = ({ result }: IParams) => {
  if (result.status === 'Loading') {
    return <div>Loading</div>;
  }
  if (result.status === 'Error') {
    return (
      <div>
        <div>Error</div>
        <div>
          {result.errorMessages.map((e, i) => {
            return <div key={i}>{e}</div>;
          })}
        </div>
      </div>
    );
  }
  return <div>Inital</div>;
};

export const FallbackSection = ({ result }: IParams) => {
  if (result.status === 'Loading') {
    return (
      <Section>
        <div>Loading</div>
      </Section>
    );
  }
  if (result.status === 'Error') {
    return (
      <Section>
        <div>
          <div>Error</div>
          <div>
            {result.errorMessages.map((e, i) => {
              return <div key={i}>{e}</div>;
            })}
          </div>
        </div>
      </Section>
    );
  }
  return (
    <Section>
      <div>Inital</div>
    </Section>
  );
};
