import { useState } from 'react';
import { Result } from '../../api/api';
import { IProductStatistic } from '../../api/types';
import { BulletList } from '../Common/BulletList';
import { Button } from '../Common/Button';
import { GridSection } from '../Common/Section';
import { ColoredPie, COLORS } from '../Graph/Pie';
import FormFileUploader from '../Firmware/FormFileUploader';
import Popup from '../Popup/Popup';
import { getDeviceType } from '../../Types/index';

interface IParams {
  productStatistic: IProductStatistic[];
  productCode: string;
  variant: string;
}

export const DevicesPerFirmwareGraph = ({
  productStatistic,
  productCode,
  variant,
}: IParams) => {
  const [showUnknown, setHideUnknown] = useState<boolean>(false);
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [isSynced, setIsSynced] = useState<Result<any>>({
    status: '',
  });

  const devicePerFirmwareAsPieData = getTop5WithColorCode(
    productStatistic,
  ).filter((a) => showUnknown || a.name !== 'Unknown');

  return (
    <GridSection>
      <div className="col-span-6">
        <div className="mr-10">
          <h2 className="text-3xl">Product</h2>
          <div className="pt-2 flex flex-row">
            <div className="font-thin pr-1">Device:</div>
            <div className="font-thick">
              {getDeviceType(productCode)}
            </div>
          </div>
          <div className="font-thin">Code: {productCode}</div>
          <div className="font-thin">Variant: {variant}</div>
          <div className="font-thin">
            devices:{' '}
            {productStatistic
              .map((d) => d.deviceCount)
              .reduce((a, b) => a + b, 0)}
          </div>
          <div className="font-thin mt-3 space-x-3">
            <Button
              onClick={() => setShowUpload(true)}
              text="Upload Firmware"
            />
            <Popup trigger={showUpload} setTrigger={setShowUpload}>
              <FormFileUploader
                productCode={productCode}
                variant={variant}
              />
            </Popup>
          </div>
        </div>
      </div>
      <div className="col-span-4">
        <label className="inline-flex items-center ml-3">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-600"
            checked={showUnknown}
            onChange={() => setHideUnknown(!showUnknown)}
          />
          <span className="ml-2 text-gray-700">
            Show unknown firmware
          </span>
        </label>
        <ColoredPie
          disabled={!showUpload}
          data={devicePerFirmwareAsPieData}
        />
      </div>
      <div className="col-span-2">
        <div className="pb-5">
          <h3 className="text-lg">Versions</h3>
        </div>
        <div>
          <BulletList elements={devicePerFirmwareAsPieData} />
        </div>
      </div>
    </GridSection>
  );
};

const getTop5WithColorCode = (
  productStatistic: IProductStatistic[],
) => {
  const statistic = productStatistic.sort((a, b) =>
    a.deviceCount < b.deviceCount ? 1 : -1,
  );

  const maxValue = Math.min(4, productStatistic.length);

  const devicesOnOtherFirmware = sum(
    statistic.slice(maxValue, productStatistic.length),
  );

  const other = {
    firmwareRelease: -2,
    deviceCount: devicesOnOtherFirmware,
  };
  const top5 = [...statistic.slice(0, maxValue), other];

  return top5.map((s, index) => {
    return {
      name: getFirmwareRelease(s.firmwareRelease),
      value: s.deviceCount,
      color: COLORS[index],
    };
  });
};

const sum = (productStatistic: IProductStatistic[]) => {
  return productStatistic
    .map((a) => a.deviceCount)
    .reduce((a, b) => a + b, 0);
};

const getFirmwareRelease = (firmwareRelease: number): string => {
  switch (firmwareRelease) {
    case -1:
      return 'Unknown';
    case -2:
      return 'Other';
    default:
      return firmwareRelease.toString();
  }
};
